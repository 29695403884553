import './index.css';
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { MetaMaskWallet } from "@thirdweb-dev/wallets";
import { WalletConnect } from "@thirdweb-dev/wallets";

const contractPackAdresse = "0xe352aEB6BB8360b0E7090AC9D00E94a856abd876";
const contractCollectionAdresse = "0xccdef31fef6ECBfc25886eEdA82b9eb08eAEC6B4";
const contractMarketplaceAdresse = "0xf34da843bF3e388794B4ABa9fF700630d36e756b";
var wallet;
var sdkWallet;
var address ;
var signer ;
var collection ;
var isPackLoaded = false;
var isCollectionLoaded = false;
var isListingLoaded = false;
var nombrePacks ;

var connectButtonElement = document.getElementById("sec-0627");
var openPackButtonElement = document.getElementById("openPackButton");
var openPacksButtonElement = document.getElementById("openPacksButton");
var buyPackButtonElement = document.getElementById("buyPackButton");
var nombrePacksElement = document.getElementById("nombrePacksText");
var nombreMyPacksElement = document.getElementById("nombreMyPacksText");
var packOpeningElement = document.getElementById("sec-033d");
var loaderConnectElement = document.getElementById("loaderConnect");
var loaderBuyElement = document.getElementById("loaderBuy");
var loaderOpenElement = document.getElementById("loaderOpen");
var hiddenButton = document.getElementById("myCollectionImage68");

document.addEventListener('DOMContentLoaded', function(event) {

document.getElementById('flip-card-btn-turn-to-back1').onclick = function() {
document.getElementById('flip-card1').classList.toggle('do-flip');
document.getElementById('reward1text').style.display = "block";
};

document.getElementById('flip-card-btn-turn-to-back2').onclick = function() {
document.getElementById('flip-card2').classList.toggle('do-flip');
document.getElementById('reward2text').style.display = "block";
};

document.getElementById('flip-card-btn-turn-to-back3').onclick = function() {
document.getElementById('flip-card3').classList.toggle('do-flip');
document.getElementById('reward3text').style.display = "block";
};

document.getElementById('flip-card-btn-turn-to-back4').onclick = function() {
document.getElementById('flip-card4').classList.toggle('do-flip');
document.getElementById('reward4text').style.display = "block";
};

document.getElementById('flip-card-btn-turn-to-back5').onclick = function() {
document.getElementById('flip-card5').classList.toggle('do-flip');
document.getElementById('reward5text').style.display = "block";
};

});

async function updateMyPacks() {

    var element = "You own no packs at the moment.";

    try{
      const contractPack = await sdkWallet.getContract(contractPackAdresse, "pack");
      const myNFT = await contractPack.getOwned(address);
      nombrePacks = 0;
      if (Array.isArray(myNFT)){
        for (var i = 0; i < myNFT.length; i++) {
          nombrePacks = nombrePacks + parseInt(myNFT[i].quantityOwned);
        }
        element = "You own "+nombrePacks+" pack(s).";
      }
      else{
        console.log('myNFT is not a pack');
      }
      if(nombrePacks>0){
        displayOpenPackButton();
        if(nombrePacks>9){
          displayOpenPacksButton();
        }
      }
      else{
        hideOpenPackButton();
      }
      nombreMyPacksElement.innerHTML = element;
      isPackLoaded = true;
      if(isPackLoaded && isCollectionLoaded && isListingLoaded){
        hideLoaderConnect();
      }
    }
    catch (error) {
      console.error("ERROR UMP "+error);
      setTimeout(updateMyPacks, 100);
    }
}

async function updateListings() {

    var element = "You own no packs at the moment.";
    try{
      const contractMarketplace = await sdkWallet.getContract(contractMarketplaceAdresse);
      const listing = await contractMarketplace.directListings.getListing("0");
      element="There are currently "+listing.quantity+" pack(s) available";
      hideLoaderBuy();
      if (listing.quantity>0){
        displayBuyPackButton();
      }
      else{
        hideBuyPackButton();
      }
      nombrePacksElement.innerHTML = element;
      isListingLoaded = true;
      if(isPackLoaded && isCollectionLoaded && isListingLoaded){
        hideLoaderConnect();
      }
    }
    catch (error) {
      console.error("ERROR UL "+error);
      setTimeout(updateListings, 100);
    }
}

async function displayMyCollection () {

  try{
    var nombreCartesDifferentes = 0;
    collection = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    const contractCollection = await sdkWallet.getContract(contractCollectionAdresse);
    const packss = await contractCollection.erc1155.getOwned(address);
    var image ;
    var nombre = 0;

    for (let i = 0; i < packss.length; i++) {
      nombre = parseInt(packss[i].metadata.id)+1;
      image = document.getElementById("myCollectionImage"+nombre);
      nombreCartesDifferentes++;
      collection[packss[i].metadata.id]=parseInt(packss[i].quantityOwned);
      image.style.filter = "brightness(100%)";
    }
    document.getElementById("number").innerHTML = nombreCartesDifferentes+"/69";
    document.getElementById("number").style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(orange "+Math.ceil(100.0*nombreCartesDifferentes/69.0)+"%, #ffddc0 0)";
    window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#first";
    isCollectionLoaded = true;
    if(isPackLoaded && isCollectionLoaded && isListingLoaded){
      hideLoaderConnect();
    }
  }
  catch (e){
    console.error("Erreur DMC "+e);
    setTimeout(displayMyCollection, 100);
  }
}

async function newDisplayMyCollection () {

  try{
    var nombreCartesDifferentes = 0;
    collection = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    const contractCollection = await sdkWallet.getContract(contractCollectionAdresse);
    const packss = await contractCollection.erc1155.getOwned("0x3b6703c5c3b83A828cbb1FD782f54cdC85220Df7");
    console.error("Erreur DMC "+stringify(packss));
    var image ;
    var nombre = 0;

    for (let i = 0; i < packss.length; i++) {
      console.error("CARD "+i);
      nombre = parseInt(packss[i].metadata.id)+1;
      console.error("NOMBRE "+nombre);
      image = document.getElementById("myCollectionImage"+nombre);
      console.error("myCollectionImage"+nombre);
      nombreCartesDifferentes++;
      collection[packss[i].metadata.id]=parseInt(packss[i].quantityOwned);
      image.style.filter = "brightness(100%)";
    }
    document.getElementById("number").innerHTML = nombreCartesDifferentes+"/69";
    document.getElementById("number").style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(orange "+Math.ceil(100.0*nombreCartesDifferentes/69.0)+"%, #ffddc0 0)";
    window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#first";
    isCollectionLoaded = true;
    if(isPackLoaded && isCollectionLoaded && isListingLoaded){
      hideLoaderConnect();
    }
  }
  catch (e){
    console.error("Erreur DMC "+e);
    setTimeout(displayMyCollection, 100);
  }
}

async function walletAutoConnect() {

  wallet = new MetaMaskWallet();

  try{
    address = await wallet.autoConnect({
      chainId: "137"
    });
    if (address===undefined) {
      hideLoaderConnect();
      displayConnect();
    }
    else {
      hideConnect();
      testChain();
    }
  }
  catch (e){
    console.error("Erreur WAC "+e);
    hideLoaderConnect();
  }
}

async function walletConnect() {

  wallet = new MetaMaskWallet();
  hideConnect();
  try{
    address = await wallet.connect({
      chainId: "137"
    });
    if (address===undefined) {
      displayConnect();
    }
    else {
      testChain();
    }
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

async function newWalletConnect() {

  wallet = new WalletConnect();
  hideConnect();
  try{
    address = await wallet.connect({
      chainId: "137"
    });
    if (address===undefined) {
      displayConnect();
    }
    else {
      testChain();
    }
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

async function testChain() {
  try{
    const chainId = await wallet.getChainId();
    if(chainId === 137){
      getSigner();
    }
    else{
      switchChain();
    }
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

async function switchChain() {
  try{
    const chainId = await wallet.switchChain("137");
    getSigner();
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

async function getSigner() {
  try{
    signer = await wallet.getSigner();
    getFromSigner();
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

async function getFromSigner() {
  try{
    sdkWallet = await ThirdwebSDK.fromWallet(wallet, "Polygon", {
  clientId: "8d1a6312841af70279dcee905294835f",
});
  resfreshAll();
  }
  catch (e){
    specialLog("Wallet connect is currently unavailable, please try later. Error : "+e);
    hideLoaderConnect();
    displayConnect();
  }
}

function stringify(obj) {
  let cache = [];
  let str = JSON.stringify(obj, function(key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
}

async function buyPack(nb) {
  hideBuyPackButton();
  hideOpenPackButton();
  displayLoaderBuy();

  try {
    const contractMarketplace = await sdkWallet.getContract(contractMarketplaceAdresse);
    const tx = await contractMarketplace.directListings.buyFromListing("0", ""+nb, address);
    isListingLoaded = false;
    isPackLoaded = false;
    updateListings();
    updateMyPacks();
  }
  catch (error) {
    specialLog("Marketplace is currently unavailable, please try later. Error : "+error);
    displayLoaderConnect();
    isListingLoaded = false;
    isPackLoaded = false;
    updateListings();
    updateMyPacks();
  }
}

async function openPack() {

  try{

  hideOpenPackButton();
  hideBuyPackButton();
  displayLoaderOpen();

  const contractPack = await sdkWallet.getContract(contractPackAdresse, "pack");

  const results = await contractPack.open("0", "1", "1500000");
  const tokens = results.erc1155Rewards;

  var textElem;
  var textElemText;
  var tokenId;
  var compteur;

  for (let i = 0; i < tokens.length ; i++) {
    tokenId = parseInt(tokens[i].tokenId);
    textElem="reward"+(i+1);
    textElemText = textElem+"text";
    console.log(textElemText);
    document.getElementById(textElem).src="images/X"+(tokenId+1)+"_69.png";
    compteur = collection[tokenId]+1;
    if(compteur===1){
      document.getElementById(textElemText).innerHTML = "NEW";
    }
    else {
      document.getElementById(textElemText).innerHTML = "Owned : "+compteur;
    }
  }

  hideLoaderOpen();
  displayPackOpening();

  } catch (error) {
    hideLoaderOpen();
    specialLog("Pack opening is currently unavailable, please try later. Error : "+error);
    hidePackOpening();
  }
  isPackLoaded = false;
  displayLoaderConnect();
  updateMyPacks();
}

async function openPacks() {

  try{

  hideOpenPackButton();
  hideBuyPackButton();
  displayLoaderOpen();

  const contractPack = await sdkWallet.getContract(contractPackAdresse, "pack");

  const results = await contractPack.open("0", "10", "15000000");

  hideLoaderOpen();
  isPackLoaded = false;
  isCollectionLoaded = false;
  updateMyPacks();
  displayMyCollection();

  } catch (error) {
    specialLog("Pack opening is currently unavailable, please try later. Error : "+error);
    hideLoaderOpen();
    isPackLoaded = false;
    isCollectionLoaded = false;
    updateMyPacks();
    displayMyCollection();
  }
}

const button = document.getElementById("buttonConnect");
button.addEventListener("click", function (event) {
    walletConnect();
});

const button2 = document.getElementById("openPackButton");
button2.addEventListener("click", function (event) {
    openPack();
});

const button8 = document.getElementById("openPacksButton");
button8.addEventListener("click", function (event) {
    openPacks();
});

const button3 = document.getElementById("buttonCollection");
button3.addEventListener("click", function (event) {
    hidePackOpening();
    returnCards();
    displayMyCollection();
});

const button4 = document.getElementById("buyPackButtonB1");
button4.addEventListener("click", function (event) {
    buyPack(1);
});

const button5 = document.getElementById("buyPackButtonB5");
button5.addEventListener("click", function (event) {
    buyPack(5);
});

const button6 = document.getElementById("buyPackButtonB20");
button6.addEventListener("click", function (event) {
    buyPack(20);
});

const button7 = document.getElementById("buyPackButtonB100");
button7.addEventListener("click", function (event) {
    buyPack(100);
});

hiddenButton.addEventListener("click", function (event) {
    newDisplayMyCollection();
});

function specialLog (e) {
  console.error("ERROR SPECIAL LOG "+e);
  alert(stringify(e));
}

function returnCards () {
  document.getElementById('flip-card1').classList.remove('do-flip');
  document.getElementById('flip-card2').classList.remove('do-flip');
  document.getElementById('flip-card3').classList.remove('do-flip');
  document.getElementById('flip-card4').classList.remove('do-flip');
  document.getElementById('flip-card5').classList.remove('do-flip');
  document.getElementById('reward1text').style.display = "none";
  document.getElementById('reward2text').style.display = "none";
  document.getElementById('reward3text').style.display = "none";
  document.getElementById('reward4text').style.display = "none";
  document.getElementById('reward5text').style.display = "none";
}

function resfreshAll () {
  updateMyPacks();
  updateListings();
  displayMyCollection();
}

function displayConnect () {
  connectButtonElement.style.display = "inline";
}

function hideConnect () {
  connectButtonElement.style.display = "none";
}

function displayOpenPackButton () {
  openPackButtonElement.style.display = "block";
  //openPacksButtonElement.style.display = "block";
}

function displayOpenPacksButton () {
  openPacksButtonElement.style.display = "block";
}

function hideOpenPackButton () {
  openPackButtonElement.style.display = "none";
  openPacksButtonElement.style.display = "none";
}

function displayBuyPackButton () {
  buyPackButtonElement.style.display = "inline";
}

function hideBuyPackButton () {
  buyPackButtonElement.style.display = "none";
}

function displayPackOpening () {
  packOpeningElement.style.display = "block";
}

function hidePackOpening () {
  packOpeningElement.style.display = "none";
}

function hideLoaderBuy () {
  loaderBuyElement.style.display = "none";
}

function displayLoaderBuy () {
  loaderBuyElement.style.display = "block";
}

function hideLoaderOpen () {
  loaderOpenElement.style.display = "none";
}

function displayLoaderOpen () {
  loaderOpenElement.style.display = "block";
}

function displayLoaderConnect () {
  loaderConnectElement.style.display = "block";
}

function hideLoaderConnect () {
  loaderConnectElement.style.display = "none";
}

walletAutoConnect();
